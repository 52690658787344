<template>
  <div class="kaizen">
    <v-card class="mx-auto" max-width="344" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">KAIZEN</div>
          <v-list-item-title class="text-h6 mb-1">
            Restaurant & Bar
          </v-list-item-title>
          <v-list-item-subtitle
            >The greatest in continental and Ghanaian cuisine</v-list-item-subtitle
          >
        </v-list-item-content>

        <v-list-item-avatar rounded="circle" size="80" color="grey">
          <v-img
            :src="require('../assets/kaizen_logo.jpg')"
            class="grey lighten-2"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-list-item-avatar>
      </v-list-item>

      <v-card-actions>
        <v-btn outlined rounded text small :to="{ name: 'menuKaizen' }">
          Menu
        </v-btn>

        <!-- <v-btn outlined rounded text small :to="{ name: 'reservationsCedar' }">
          Reservations
        </v-btn>

        <v-btn outlined rounded text small :to="{ name: 'reviewsCedar' }">
          Reviews
        </v-btn> -->

        <v-btn href="https://www.instagram.com/kaizenksi/" icon>
          <v-icon>mdi-instagram</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Kaizen",
  components: {},

  data() {
    return {};
  },

  methods: {},
  watch: {
    $route() {},
  },
  mounted() {},
  computed: {},
};
</script>
