<template>
  <div class="menu-808">
    <v-container class="pa-3 mb-5 mt-10" fluid>
      <v-img :src="require('../assets/menu1.jpg')" class="grey lighten-2">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img :src="require('../assets/menu2.jpg')" class="grey lighten-2">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Menu808",
  components: {},

  data() {
    return {};
  },

  methods: {},
  watch: {
    $route() {},
  },
  mounted() {},
  computed: {},
};
</script>
