import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Menu808 from '../views/Menu808.vue'
import Casa from '../views/Casa.vue'
import MenuCasa from '../views/MenuCasa.vue'
import ReservationsCasa from '../views/ReservationsCasa.vue'
import ReviewsCasa from '../views/ReviewsCasa.vue'
import JobsCasa from '../views/JobsCasa.vue'
import Luuma from '../views/Luuma.vue'
import MenuLuuma from '../views/MenuLuuma.vue'
import ReservationsLuuma from '../views/ReservationsLuuma.vue'
import ReviewsLuuma from '../views/ReviewsLuuma.vue'
import Cedar from '../views/Cedar.vue'
import MenuCedar from '../views/MenuCedar.vue'
import ReservationsCedar from '../views/ReservationsCedar.vue'
import ReviewsCedar from '../views/ReviewsCedar.vue'
import Kaizen from '../views/Kaizen.vue'
import MenuKaizen from '../views/MenuKaizen.vue'
import Wavely from '../views/Wavely.vue'
import JobsWavely from '../views/JobsWavely.vue'
import Ambe from '../views/Ambe.vue'
import MenuAmbe from '../views/MenuAmbe.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/808/menu/',
    name: 'menu808',
    component: Menu808
  },
  {
    path: '/casa/',
    name: 'casa',
    component: Casa
  },
  {
    path: '/casa/menu',
    name: 'menuCasa',
    component: MenuCasa
  },
  {
    path: '/casa/reservations',
    name: 'reservationsCasa',
    component: ReservationsCasa
  },
  {
    path: '/casa/reviews',
    name: 'reviewsCasa',
    component: ReviewsCasa
  },
  {
    path: '/casa/jobs',
    name: 'jobsCasa',
    component: JobsCasa
  },
  {
    path: '/luuma/',
    name: 'luuma',
    component: Luuma
  },
  {
    path: '/luuma/menu',
    name: 'menuLuuma',
    component: MenuLuuma
  },
  {
    path: '/luuma/reservations',
    name: 'reservationsLuuma',
    component: ReservationsLuuma
  },
  {
    path: '/luuma/reviews',
    name: 'reviewsLuuma',
    component: ReviewsLuuma
  },
  {
    path: '/cedar/',
    name: 'cedar',
    component: Cedar
  },
  {
    path: '/cedar/menu',
    name: 'menuCedar',
    component: MenuCedar
  },
  {
    path: '/cedar/reservations',
    name: 'reservationsCedar',
    component: ReservationsCedar
  },
  {
    path: '/cedar/reviews',
    name: 'reviewsCedar',
    component: ReviewsCedar
  },
  {
    path: '/kaizen/',
    name: 'kaizen',
    component: Kaizen
  },
  {
    path: '/kaizen/menu',
    name: 'menuKaizen',
    component: MenuKaizen
  },
  {
    path: '/wavely/',
    name: 'wavely',
    component: Wavely
  },
  {
    path: '/wavely/jobs',
    name: 'jobsWavely',
    component: JobsWavely
  },
  {
    path: '/ambe/',
    name: 'ambe',
    component: Ambe
  },
  {
    path: '/ambe/menu',
    name: 'menuAmbe',
    component: MenuAmbe
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
