<template>
  <div class="reviews-luuma">
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="auto">
          <v-card
            color="white"
            class="pa-8"
            light
            elevation="2"
            :width="cardWidth"
          >
            <v-card-title class="pa-0 mb-2 font-weight-black"
              >Luuma Reviews Form</v-card-title
            >
            <v-card-subtitle class="text-left pa-0 mb-3 font-weight-black">
              Fill out this form for your reviews so we can best meet your needs.
            </v-card-subtitle>
            <v-form ref="form" @submit.prevent v-model="valid" lazy-validation>
              <v-text-field
                v-model="name"
                :counter="100"
                :rules="nameRules"
                label="Name"
                color="primary"
                :class="{ 'mb-3': !valid }"
                validate-on-blur
                outlined
              ></v-text-field>

              <v-text-field
                type="email"
                v-model="email"
                :rules="emailRules"
                label="Email"
                color="primary"
                :class="{ 'mb-3': !valid }"
                validate-on-blur
                outlined
              ></v-text-field>

              <v-text-field
                v-model="phone"
                :rules="phoneRules"
                label="Phone"
                color="primary"
                :class="{ 'mb-3': !valid }"
                outlined
                dense
                counter=""
              ></v-text-field>

              <v-text-field
                v-model="foodPresentation"
                :counter="100"
                :rules="textRules"
                label="Food Presentation"
                color="primary"
                :class="{ 'mb-3': !valid }"
                validate-on-blur
                outlined
              ></v-text-field>

              <v-text-field
                v-model="foodTemperature"
                :counter="100"
                :rules="textRules"
                label="Food Temperature"
                color="primary"
                :class="{ 'mb-3': !valid }"
                validate-on-blur
                outlined
              ></v-text-field>

              <v-text-field
                v-model="foodTasteQuality"
                :counter="100"
                :rules="textRules"
                label="Food Taste & Quality"
                color="primary"
                :class="{ 'mb-3': !valid }"
                validate-on-blur
                outlined
              ></v-text-field>

              <v-text-field
                v-model="waitTime"
                :counter="100"
                :rules="textRules"
                label="Wait Time"
                color="primary"
                :class="{ 'mb-3': !valid }"
                validate-on-blur
                outlined
              ></v-text-field>

              <v-text-field
                v-model="ambiance"
                :counter="100"
                :rules="textRules"
                label="Ambiance"
                color="primary"
                :class="{ 'mb-3': !valid }"
                validate-on-blur
                outlined
              ></v-text-field>

              <v-text-field
                v-model="staffAttitude"
                :counter="100"
                :rules="textRules"
                label="Staff Attitude"
                color="primary"
                :class="{ 'mb-3': !valid }"
                validate-on-blur
                outlined
              ></v-text-field>

              <v-textarea
                v-model="comment"
                label="Comment"
                color="primary"
                :rows="1"
                outlined
                dense
                auto-grow
                hint="General Comment and any staff you would like to recommend"
                persistent-hint
              ></v-textarea>

              <div class="d-flex justify-end">
                <v-btn
                  outlined
                  color="primary"
                  class="text-capitalize"
                  @click="submit()"
                  :loading="isLoading"
                  >Submit</v-btn
                >
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ReviewsLuuma",

  data: () => ({
    valid: true,
    isLoading: false,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 100) || "Name must be at most 100 characters",
    ],
    email: "",
    phone: "",
    phoneRules: [
      (v) => !!v || "Enter an active phone number",
      (v) => !isNaN(v) || "Phone number must be valid",
    ],
    textRules: [
      (v) => !!v || "Text is required",
      (v) => (v && v.length <= 100) || "This field must be at most 100 characters",
    ],
    foodPresentation: "",
    foodTemperature: "",
    foodTasteQuality: "",
    waitTime: "",
    ambiance: "",
    staffAttitude: "",
    comment: "",
  }),

  watch: {},

  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;

        const review = {
          vendor: "LUUMA",
          name: this.name,
          email: this.email,
          phone: this.phone,
          food_presentation: this.foodPresentation,
          food_temperature: this.foodTemperature,
          food_taste_quality: this.foodTasteQuality,
          wait_time: this.waitTime,
          ambiance: this.ambiance,
          staff_attitude: this.staffAttitude,
          comment: this.comment,
        };

        await axios
          .post("/api/v2/business/reviews/submit_inquiry/", review)
          .then(() => {
            this.$refs.form.reset();
            this.comment = "";
            this.setSnackbar(
              "Submitted. We would contact you shortly.",
              "success"
            );
          })
          .catch((error) => {
            this.$store.dispatch("handleError", error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    setSnackbar(msg, color) {
      var payload = {
        state: true,
        color: color,
        comment: msg,
      };
      this.$store.commit("setSnackbar", payload);
    },
  },

  mounted() {},

  computed: {
    cardWidth() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs") {
        return "99vw";
      } else {
        return "45vw";
      }
    },

    emailRules() {
      const rules = [];

      const rule = (v) => (v && /.+@.+/.test(v)) || "Email must be valid";
      rules.push(rule);

      return rules;
    },
  },
};
</script>

<style scoped>
</style>