<template>
  <div class="menu-kaizen">
    <v-container class="pa-3 mb-5 mt-10" fluid>
      <v-img
        :src="require('../assets/kaizen_1.jpg')"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img
        :src="require('../assets/kaizen_2.jpg')"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img
        :src="require('../assets/kaizen_3.jpg')"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img
        :src="require('../assets/kaizen_4.jpg')"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img
        :src="require('../assets/kaizen_5.jpg')"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img
        :src="require('../assets/kaizen_6.jpg')"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img :src="require('../assets/kaizen_7.jpg')" class="grey lighten-2">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img :src="require('../assets/kaizen_8.jpg')" class="grey lighten-2">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img :src="require('../assets/kaizen_9.jpg')" class="grey lighten-2">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "MenuKaizen",
  components: {},

  data() {
    return {};
  },

  methods: {},
  watch: {
    $route() {},
  },
  mounted() {},
  computed: {},
};
</script>
