<template>
  <div class="home">
    <img
      class="mx-auto"
      :src="require('../assets/vendoorr_logo.jpeg')"
      style="height: 100%; width: auto; max-width: 100%"
      alt=""
    />
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},

  computed: {
    viewportSize() {
      return this.$vuetify.breakpoint.width;
    },
  },
};
</script>

<style scoped>
.image--text {
  letter-spacing: 3px;
}
.right--text {
  position: absolute;
  right: 10%;
  bottom: 45%;
}

.left--text {
  position: absolute;
  left: 10%;
  bottom: 45%;
}

@media screen and (max-width: 599px) {
  .image--text {
    font-size: 0.85rem;
    font-weight: 500;
  }
}
@media screen and (min-width: 600px) {
  .image--text {
    font-size: 1rem;
    font-weight: 500;
  }
}
</style>