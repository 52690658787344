<template>
  <div class="reservations-luuma">
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="auto">
          <v-card
            color="white"
            class="pa-8"
            light
            elevation="2"
            :width="cardWidth"
          >
            <v-card-title class="pa-0 mb-2 font-weight-black"
              >Luuma Reservations Form</v-card-title
            >
            <v-card-subtitle class="text-left pa-0 mb-3 font-weight-black">
              For your reservations, fill this form. We will contact you
              shortly!
            </v-card-subtitle>
            <v-form ref="form" @submit.prevent v-model="valid" lazy-validation>
              <v-text-field
                v-model="name"
                :counter="100"
                :rules="nameRules"
                label="Name"
                color="primary"
                :class="{ 'mb-3': !valid }"
                validate-on-blur
                outlined
              ></v-text-field>

              <v-text-field
                type="email"
                v-model="email"
                :rules="emailRules"
                label="Email"
                color="primary"
                :class="{ 'mb-3': !valid }"
                validate-on-blur
                outlined
              ></v-text-field>

              <v-text-field
                v-model="phone"
                :rules="phoneRules"
                label="Phone"
                color="primary"
                :class="{ 'mb-3': !valid }"
                outlined
                dense
                counter=""
              ></v-text-field>

              <v-text-field
                v-model="numberOfGuests"
                type="number"
                :rules="numberOfGuestsRules"
                label="No.# of Guests"
                color="primary"
                :class="{ 'mb-3': !valid }"
                outlined
                dense
                counter=""
              ></v-text-field>

              <v-select
                v-model="reservationType"
                :items="items"
                label="Reservation Type"
                dense
              ></v-select>

              <v-row>
                <v-col cols="6">
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        :rules="dateRules"
                        label="Date"
                        color="primary darken-4"
                        prepend-icon="mdi-calendar"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>

                    <v-date-picker
                      v-model="date"
                      @input="setDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="6">
                  <v-menu
                    ref="fromTMenu"
                    v-model="timeMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="time"
                        :rules="timeRules"
                        label="Time"
                        prepend-icon="mdi-clock-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        color="primary darken-4"
                        outlined
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="timeMenu"
                      v-model="time"
                      full-width
                      @click:minute="$refs.fromTMenu.save(time)"
                      @input="setTime"
                      format="24hr"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-textarea
                v-model="extraInformation"
                label="Extra Information"
                color="primary"
                :rows="1"
                outlined
                dense
                auto-grow
                hint="Tell us more"
                persistent-hint
              ></v-textarea>

              <div class="d-flex justify-end">
                <v-btn
                  outlined
                  color="primary"
                  class="text-capitalize"
                  @click="submit()"
                  :loading="isLoading"
                  >Submit</v-btn
                >
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ReservationsLuuma",

  data: () => ({
    valid: true,
    isLoading: false,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 100) || "Name must be at most 100 characters",
    ],
    email: "",
    phone: "",
    phoneRules: [
      (v) => !!v || "Enter an active phone number",
      (v) => !isNaN(v) || "Phone number must be valid",
    ],
    numberOfGuests: "",
    numberOfGuestsRules: [
      (v) => !!v || "Enter a valid number",
      (v) => !isNaN(v) || "Provide a valid number",
    ],
    items: ["Dinner", "Birthday / Anniversary", "Private", "Corporate", "VIP"],
    reservationType: null,
    date: null,
    dateMenu: false,
    dateRules: [(v) => !!v || "Date is required"],
    timeMenu: false,
    time: null,
    timeRules: [(v) => !!v || "Time is required"],
    extraInformation: "",
  }),

  watch: {},

  methods: {
    setDate(value) {
      this.date = value;
      this.dateMenu = false;
    },

    setTime(value) {
      this.time = value;
    },

    async submit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;

        const reservation = {
          vendor: "LUUMA",
          name: this.name,
          email: this.email,
          phone: this.phone,
          number_of_guests: this.numberOfGuests,
          reservation_type: this.reservationType,
          set_date: this.date,
          set_time: this.time,
          extra_information: this.extraInformation,
        };

        await axios
          .post("/api/v2/business/reservations/submit_inquiry/", reservation)
          .then(() => {
            this.$refs.form.reset();
            this.extraInformation = "";
            this.setSnackbar(
              "Submitted. We would contact you shortly.",
              "success"
            );
          })
          .catch((error) => {
            this.$store.dispatch("handleError", error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    setSnackbar(msg, color) {
      var payload = {
        state: true,
        color: color,
        comment: msg,
      };
      this.$store.commit("setSnackbar", payload);
    },
  },

  mounted() {},

  computed: {
    cardWidth() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs") {
        return "99vw";
      } else {
        return "45vw";
      }
    },

    emailRules() {
      const rules = [];

      const rule = (v) => (v && /.+@.+/.test(v)) || "Email must be valid";
      rules.push(rule);

      return rules;
    },
  },
};
</script>

<style scoped>
</style>