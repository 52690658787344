<template>
  <div class="jobs-wavely">
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="auto">
          <v-card
            color="white"
            class="pa-8"
            light
            elevation="2"
            :width="cardWidth"
          >
            <v-card-title class="pa-0 mb-2 font-weight-black"
              >Wavely Hospitality Services</v-card-title
            >
            <v-card-title class="pa-0 mb-4"
              >Job Application Form</v-card-title
            >
            <v-card-subtitle class="text-left pa-0 mb-3 font-weight-black">
              Provide the required details to apply for a job at Wavely
            </v-card-subtitle>
            <v-form ref="form" @submit.prevent v-model="valid" lazy-validation>
              

              <v-select v-model="interviewReady" :rules="interviewReadyRules" :items="interviewReadiness" label="Ready for an interview?" dense></v-select>

              <v-text-field
                v-model="name"
                :counter="100"
                :rules="nameRules"
                label="Name"
                color="primary"
                :class="{ 'mb-3': !valid }"
                validate-on-blur
                outlined
              ></v-text-field>

              <v-text-field
                v-model="phone"
                :rules="phoneRules"
                label="Phone"
                color="primary"
                :class="{ 'mb-3': !valid }"
                outlined
                dense
                counter=""
              ></v-text-field>

              <v-menu v-model="dateOfBirthMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dateOfBirth" :rules="dateOfBirthRules" label="Date of Birth" color="primary darken-4" prepend-icon="mdi-calendar"
                    readonly outlined v-bind="attrs" v-on="on"></v-text-field>
                </template>
              
                <v-date-picker v-model="dateOfBirth" @input="setDate"></v-date-picker>
              </v-menu>
              
              <v-text-field
                v-model="residence"
                :rules="residenceRules"
                label="Residence"
                color="primary"
                :class="{ 'mb-3': !valid }"
                outlined
                dense
                :counter="100"
              ></v-text-field>    
              
              <v-select v-model="hospitalityExperience" :rules="hospitalityExperienceRules" :items="hospitalityExperienced" label="Any Experience in Hospitality?" dense></v-select>

              <v-text-field v-model="experienceDuration" :rules="experienceDurationRules" label="Duration of Experience" color="primary"
                :class="{ 'mb-3': !valid }" outlined dense :counter="100">
              </v-text-field>

              <v-select v-model="role" :rules="rolesRules" :items="roles" label="Preferred Role" dense>
              </v-select>

              <v-text-field v-model="occupation" :rules="occupationRules" label="Current Occupation" color="primary"
                :class="{ 'mb-3': !valid }" outlined dense :counter="100">
              </v-text-field>

              <v-text-field v-model="jobExperience" :rules="jobExperienceRules" label="Job Experience" color="primary" :class="{ 'mb-3': !valid }"
                outlined dense :counter="100">
              </v-text-field>

              <v-textarea
                v-model="referees"
                :rules="refereesRules"
                label="Details of Referees"
                color="primary"
                :rows="3"
                outlined
                dense
                auto-grow
                persistent-hint
              ></v-textarea>

              <div class="d-flex justify-end">
                <v-btn
                  outlined
                  color="primary"
                  class="text-capitalize"
                  @click="submit()"
                  :loading="isLoading"
                  >Apply</v-btn
                >
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "JobsCasa",

  data: () => ({
    valid: true,
    isLoading: false,
    interviewReadiness: [
      "Yes",
      "No",
    ],
    interviewReadyRules: [(v) => !!v || "Select an option"],
    interviewReady: null,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 100) || "Name must be at most 100 characters",
    ],
    phone: "",
    phoneRules: [
      (v) => !!v || "Enter an active phone number",
      (v) => !isNaN(v) || "Phone number must be valid",
    ],
    dateOfBirth: null,
    dateOfBirthMenu: false,
    dateOfBirthRules: [(v) => !!v || "Your Date of Birth is required"],
    residence: "",
    residenceRules: [
      (v) => !!v || "Enter your residence",
    ],
    hospitalityExperienced: [
      "Yes",
      "No",
    ],
    hospitalityExperienceRules: [(v) => !!v || "Select an option"],
    hospitalityExperience: null,
    roles: [
      "Waiting",
      "Manager",
      "Supervisor",
      "Kitchen Assistant",
      "Cashier",
      "Accountant",
      "Chef",
      "Cook",
      "Bartender",
      "Cleaner",
      "Pantry",
      "Security",
      "Bouncer",
      "Hosting / Receptionist",
      "Purchasing Officer",
      "Apartment / Hotel Housekeeping",
      "Apartment / Hotel Manager",
      "Potter",
      "SPA Manager",
      "SPA Worker",
      "Saloon Worker",
    ],
    rolesRules: [
      (v) => !!v || "Select the role you are applying for",
    ],
    role: null,
    experienceDuration: "",
    experienceDurationRules: [(v) => !!v || "Tell us the duration of your experience in hospitality"],
    occupation: "",
    occupationRules: [
      (v) => !!v || "State your current occupation",
    ],
    jobExperience: "",
    jobExperienceRules: [
      (v) => !!v || "State your job experience",
    ],
    referees: "",
    refereesRules: [
      (v) => !!v || "Provide your referees information",
    ],
  }),

  watch: {},

  methods: {
    setDate(value) {
      this.dateOfBirth = value;
      this.dateOfBirthMenu = false;
    },

    async submit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;

        let formData = new FormData();

        formData.append("interview_ready", this.interviewReady)
        formData.append("name", this.name)
        formData.append("phone", this.phone)
        formData.append("date_of_birth", this.dateOfBirth)
        formData.append("residence", this.residence)
        formData.append("hospitality_experience", this.hospitalityExperience)
        formData.append("role", this.role)
        formData.append("experience_duration", this.experienceDuration)
        formData.append("occupation", this.occupation)
        formData.append("job_experience", this.jobExperience)
        formData.append("referees", this.referees)

        await axios
          .post("/api/v2/business/wavely/submit_application/", formData)
          .then(() => {
            this.$refs.form.reset();
            this.referees = "";
            this.setSnackbar(
              "Your Job Application has been submitted. We would contact you.",
              "success"
            );
          })
          .catch((error) => {
            this.$store.dispatch("handleError", error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    setSnackbar(msg, color) {
      var payload = {
        state: true,
        color: color,
        comment: msg,
      };
      this.$store.commit("setSnackbar", payload);
    },
  },

  mounted() {},

  computed: {
    cardWidth() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs") {
        return "99vw";
      } else {
        return "45vw";
      }
    },
  },
};
</script>

<style scoped>
</style>