<template>
  <div class="menu-luuma">
    <v-container class="pa-3 mb-5 mt-10" fluid>
      <v-img
        :src="require('../assets/luuma_drinks.jpg')"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img
        :src="require('../assets/luuma_drinks_1.jpg')"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img
        :src="require('../assets/luuma_drinks_2.jpg')"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img
        :src="require('../assets/luuma_drinks_3.jpg')"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img
        :src="require('../assets/luuma_drinks_4.jpg')"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img
        :src="require('../assets/luuma_drinks_5.jpg')"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img :src="require('../assets/luuma_drinks_6.jpg')" class="grey lighten-2">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img :src="require('../assets/luuma_food.jpeg')" class="grey lighten-2">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img :src="require('../assets/luuma_food_1.jpeg')" class="grey lighten-2">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img :src="require('../assets/luuma_food_2.jpeg')" class="grey lighten-2">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img :src="require('../assets/luuma_food_3.jpeg')" class="grey lighten-2">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "MenuCasa",
  components: {},

  data() {
    return {};
  },

  methods: {},
  watch: {
    $route() {},
  },
  mounted() {},
  computed: {},
};
</script>
