import axios from "axios";
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    products: [],

    snackbar: false,
    snackbarColor: "",
    snackbarMessage: "",
  },
  mutations: {
    refreshProducts(state, payload) {
      state.products = [];
      state.products = payload;
    },

    setSnackbar(state, payload) {
      state.snackbar = payload.state;
      state.snackbarColor = payload.color;
      state.snackbarMessage = payload.comment;
    },
  },
  actions: {
    getProducts({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/v1/products/product/get_products/`
          )
          .then((response) => {
            commit("refreshProducts", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    handleError({ commit }, error) {
      var msg;
      if (error.response) {
        var response = error.response;
        var status = response.status;

        if (status === 403) {
          if (response.data && response.data.detail) msg = response.data.detail;
          else msg = "You are restricted";
        } else if (status === 400 || status === 401) {
          if (response.data && response.data.message)
            msg = response.data.message;
          else if (response.data) msg = response.data;
          else msg = "Something went wrong";

          if (status === 401) {
            msg = "Unauthorized";
          }
        } else if (response.status === 500) {
          msg = "An error occurred. Try again";
        } else if (response.status === 404) {
          msg = "Not Found";
        } else {
          msg = "An error occurred";
        }
      } else {
        msg = "Check connection and try again";
      }

      //Set Snackbar
      commit("setSnackbar", {
        state: true,
        color: "error",
        comment: msg,
      });
    },
  },
  modules: {
  }
})
