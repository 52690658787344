<template>
  <div class="jobs-casa">
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="auto">
          <v-card
            color="white"
            class="pa-8"
            light
            elevation="2"
            :width="cardWidth"
          >
            <v-card-title class="pa-0 mb-2 font-weight-black"
              >Casa Job Application</v-card-title
            >
            <v-card-subtitle class="text-left pa-0 mb-3 font-weight-black">
              Provide the required details to apply for a job at Casa
            </v-card-subtitle>
            <v-form ref="form" @submit.prevent v-model="valid" lazy-validation>
              

              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="firstName" :counter="100" :rules="firstNameRules" label="First Name" color="primary" :class="{ 'mb-3': !valid }"
                    validate-on-blur outlined></v-text-field>
                </v-col>
              
                <v-col cols="6">
                  <v-text-field v-model="lastName" :counter="100" :rules="lastNameRules" label="Last Name" color="primary" :class="{ 'mb-3': !valid }"
                    validate-on-blur outlined></v-text-field>
                </v-col>
              </v-row>

              <v-text-field
                type="email"
                v-model="email"
                :rules="emailRules"
                label="Email"
                color="primary"
                :class="{ 'mb-3': !valid }"
                validate-on-blur
                outlined
              ></v-text-field>

              <v-text-field
                v-model="phone"
                :rules="phoneRules"
                label="Phone"
                color="primary"
                :class="{ 'mb-3': !valid }"
                outlined
                dense
                counter=""
              ></v-text-field>

              <v-menu v-model="dateOfBirthMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dateOfBirth" :rules="dateOfBirthRules" label="Date of Birth" color="primary darken-4" prepend-icon="mdi-calendar"
                    readonly outlined v-bind="attrs" v-on="on"></v-text-field>
                </template>
              
                <v-date-picker v-model="dateOfBirth" @input="setDate"></v-date-picker>
              </v-menu>
              
              <v-select v-model="region" :rules="regionRules" :items="regions" label="Region Of Residence" dense></v-select>

              <v-text-field
                v-model="town"
                :rules="townRules"
                label="Town of Residence"
                color="primary"
                :class="{ 'mb-3': !valid }"
                outlined
                dense
                :counter="100"
              ></v-text-field>    
              
              <v-select v-model="educationalLevel" :rules="educationalLevelRules" :items="educationalLevels" label="Educational Background" dense></v-select>

              <v-select v-model="position" :rules="positionsRules" :items="positions" label="Position Applying For" dense>
              </v-select>

              <v-select v-model="workingHours" :rules="workingHoursRules" :items="workingHoursList"
                label="Working Hours" dense></v-select>

              <v-file-input v-model="cv" :rules="cvRules" accept="image/png, image/jpeg, image/bmp" show-size placeholder="Upload your CV"
                prepend-icon="mdi-camera" label="Upload CV" @change="uploadFile"></v-file-input>

              <v-text-field v-model="startDate" :rules="startDateRules" label="When can you start" color="primary"
                :class="{ 'mb-3': !valid }" outlined dense :counter="100">
              </v-text-field>

              <v-text-field v-model="lastWork" label="Recent Work Place" color="primary"
                :class="{ 'mb-3': !valid }" outlined dense :counter="100">
              </v-text-field>

              <v-text-field v-model="roleAtWork" label="Role at Recent Work Place" color="primary"
                :class="{ 'mb-3': !valid }" outlined dense :counter="100">
              </v-text-field>

              <v-text-field v-model="workDuration" label="Recent Work Place Duration" color="primary" :class="{ 'mb-3': !valid }"
                outlined dense :counter="100">
              </v-text-field>

              <v-textarea
                v-model="extraInformation"
                label="Extra Information"
                color="primary"
                :rows="1"
                outlined
                dense
                auto-grow
                hint="Tell us more"
                persistent-hint
              ></v-textarea>

              <div class="d-flex justify-end">
                <v-btn
                  outlined
                  color="primary"
                  class="text-capitalize"
                  @click="submit()"
                  :loading="isLoading"
                  >Apply</v-btn
                >
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "JobsCasa",

  data: () => ({
    valid: true,
    isLoading: false,
    firstName: "",
    firstNameRules: [
      (v) => !!v || "First Name is required",
      (v) => (v && v.length <= 100) || "First Name must be at most 100 characters",
    ],
    lastName: "",
    lastNameRules: [
      (v) => !!v || "Last Name is required",
      (v) => (v && v.length <= 100) || "Last Name must be at most 100 characters",
    ],
    email: "",
    phone: "",
    phoneRules: [
      (v) => !!v || "Enter an active phone number",
      (v) => !isNaN(v) || "Phone number must be valid",
    ],
    dateOfBirth: null,
    dateOfBirthMenu: false,
    dateOfBirthRules: [(v) => !!v || "Your Date of Birth is required"],
    regions: [
      "Ahafo",
      "Ashanti",
      "Bono East",
      "Brong Ahafo",
      "Central",
      "Eastern",
      "Greater Accra",
      "North East",
      "Northern",
      "Oti",
      "Savannah",
      "Upper East",
      "Upper West",
      "Western",
      "Western North",
      "Volta"
    ],
    regionRules: [(v) => !!v || "You must select a region"],
    region: null,
    town: "",
    townRules: [
      (v) => !!v || "Enter your town of residence",
    ],
    educationalLevels: [
      "Junior High School",
      "Senior High School",
      "Diploma Tertiary",
      "Undergraduate Tertiary",
      "Postgraduate Tertiary"
    ],
    educationalLevelRules: [(v) => !!v || "Provide your most recent Educational Background"],
    educationalLevel: null,
    positions: [
      "Waiter / Waitress",
      "Food Supervisor",
      "Kitchen Staff",
      "Cleaner",
      "Bar-back"
    ],
    positionsRules: [
      (v) => !!v || "Select the position you are applying for",
    ],
    position: null,
    workingHours: null,
    workingHoursList: [
      "Part Time",
      "Full Time"
    ],
    workingHoursRules: [(v) => !!v || "Select your preferred working hours"],
    cvRules: [
      (v) => !!v || "You must provide your CV",
      value => !value || value.size < 2000000 || 'CV image should be less than 2 MB!',
    ],
    cv: null,
    startDate: "",
    startDateRules: [(v) => !!v || "Tell us when you can start"],
    lastWork: "",
    roleAtWork: "",
    workDuration: "",
    extraInformation: "",
  }),

  watch: {},

  methods: {
    setDate(value) {
      this.dateOfBirth = value;
      this.dateOfBirthMenu = false;
    },

    setTime(value) {
      this.time = value;
    },

    uploadFile(file) {
      this.cv = file
    },

    async submit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;

        let formData = new FormData();

        formData.append("cv", this.cv)
        formData.append("first_name", this.firstName)
        formData.append("last_name", this.lastName)
        formData.append("email", this.email)
        formData.append("phone", this.phone)
        formData.append("date_of_birth", this.dateOfBirth)
        formData.append("region", this.region)
        formData.append("town", this.town)
        formData.append("educational_level", this.educationalLevel)
        formData.append("position", this.position)
        formData.append("working_hours", this.workingHours)
        formData.append("start_date", this.startDate)
        formData.append("last_work", this.lastWork)
        formData.append("role_at_work", this.roleAtWork)
        formData.append("work_duration", this.workDuration)
        formData.append("extra_information", this.extraInformation)

        await axios
          .post("/api/v2/business/jobs/submit_application/", formData)
          .then(() => {
            this.$refs.form.reset();
            this.extraInformation = "";
            this.setSnackbar(
              "Your Job Application has been submitted. We would contact you.",
              "success"
            );
          })
          .catch((error) => {
            this.$store.dispatch("handleError", error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    setSnackbar(msg, color) {
      var payload = {
        state: true,
        color: color,
        comment: msg,
      };
      this.$store.commit("setSnackbar", payload);
    },
  },

  mounted() {},

  computed: {
    cardWidth() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs") {
        return "99vw";
      } else {
        return "45vw";
      }
    },

    emailRules() {
      const rules = [];

      const rule = (v) => (v && /.+@.+/.test(v)) || "Email must be valid";
      rules.push(rule);

      return rules;
    },
  },
};
</script>

<style scoped>
</style>