import { render, staticRenderFns } from "./JobsWavely.vue?vue&type=template&id=7a9a455d&scoped=true"
import script from "./JobsWavely.vue?vue&type=script&lang=js"
export * from "./JobsWavely.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a9a455d",
  null
  
)

export default component.exports