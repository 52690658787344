import { render, staticRenderFns } from "./ReservationsCedar.vue?vue&type=template&id=10230342&scoped=true"
import script from "./ReservationsCedar.vue?vue&type=script&lang=js"
export * from "./ReservationsCedar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10230342",
  null
  
)

export default component.exports