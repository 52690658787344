<template>
  <div id="app">
    <v-app>
      <v-app-bar app color="white" shrink-on-scroll prominent>
        <img
          class="mx-auto"
          :src="require('./assets/vendoorr_logo.png')"
          style="height: 100%; width: auto; max-width: 100%"
          alt=""
        />
      </v-app-bar>

      <v-main class="grey---bg">
        <router-view />
      </v-main>

      <v-footer padless>
        <v-row
          class="vendoorr---bg pa-5 py-sm-10 px-sm-15"
          justify="center"
          no-gutters
        >
          <v-col class="white--text"> Vendoorr Technologies © 2024 </v-col>
        </v-row>
      </v-footer>

      <v-snackbar
        app
        v-model="snackbarStatus"
        transition="fade-transition"
        :color="snackbarColor"
        multi-line
        vertical
        bottom
      >
        {{ snackbarMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            dark
            class="text-none"
            text
            @click="closeSnackbar"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-app>
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          route: {
            name: "home",
          },
          text: "Home",
        },
        {
          route: {
            name: "menu808",
          },
          text: "Menu 808",
        },
      ],
    };
  },

  components: {},

  watch: {},

  methods: {
    closeSnackbar() {
      this.$store.commit("setSnackbar", { state: false });
    },
  },

  mounted() {},

  computed: {
    viewportSize() {
      return this.$vuetify.breakpoint.width;
    },

    snackbarStatus: {
      get: function () {
        return this.$store.state.snackbar;
      },
      set: function (state) {
        this.$store.commit("setSnackbar", { state: state });
      },
    },
    snackbarColor() {
      return this.$store.state.snackbarColor;
    },
    snackbarMessage() {
      return this.$store.state.snackbarMessage;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.footer-link {
  color: #ffffff;
  text-decoration: none;
}

.footer-link:hover {
  color: #e3b554;
  text-decoration: underline;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.grey---text {
  color: #f3f3f3;
}
.grey---bg {
  background-color: #f3f3f3;
}

.gold---text {
  color: #e3b554;
}
.gold---bg {
  background-color: #e3b554;
}
.black---text {
  color: #1f1d1e;
}
.vendoorr---bg {
  background-color: #012a7a;
}
</style>
