<template>
  <div class="menu-cedar">
    <v-container class="pa-3 mb-5 mt-10" fluid>
      <v-img
        :src="require('../assets/ambe_1.jpeg')"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img
        :src="require('../assets/ambe_2.jpeg')"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img
        :src="require('../assets/ambe_3.jpeg')"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img
        :src="require('../assets/ambe_4.jpeg')"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img
        :src="require('../assets/ambe_5.jpeg')"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img
        :src="require('../assets/ambe_6.jpeg')"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>


    <v-container class="pa-3 mt-10" fluid>
      <v-img :src="require('../assets/ambe_7.jpeg')" class="grey lighten-2">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>

    <v-container class="pa-3 mt-10" fluid>
      <v-img :src="require('../assets/ambe_8.jpeg')" class="grey lighten-2">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "MenuCasa",
  components: {},

  data() {
    return {};
  },

  methods: {},
  watch: {
    $route() {},
  },
  mounted() {},
  computed: {},
};
</script>
